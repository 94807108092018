/* https://github.com/web-tiki/responsive-grid-of-hexagons/tree/css-grid */

#hexGrid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(10, 1fr);
  width: 90%;
  margin: 0 auto;
  list-style-type: none;
  overflow: hidden;
}

.hex {
  grid-column-end: span 2;
  position: relative;
  visibility: hidden;
  /* fix for jagged edges in FF on hover transition */
  outline: 1px solid transparent;
}

.hex::after {
  content: '';
  display: block;
  padding-bottom: 86.602%;
  /* =  100 / tan(60) * 1.5 */
}

.hexIn {
  position: absolute;
  width: 100%;
  padding-bottom: 115.470%;
  /* =  width / sin(60) */
  overflow: hidden;
  visibility: hidden;
  /* fix for jagged edges in FF on hover transition */
  outline: 1px solid transparent;
  -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
}

.hexIn .hexLink,
.hexIn .hexLink .hexTitle,
.hexIn .hexLink .hexDescription {
  position: absolute;
  visibility: visible;
  /* fix for jagged edges in FF on hover transition */
  outline: 1px solid transparent;
}

.hexLink {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  -ms-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
}

/*** HEX CONTENT **********************************************************************/
.hex .hexImg {
  left: -100%;
  right: -100%;
  width: auto;
  height: 100%;
  margin: 0 auto;
  -webkit-transform: rotate3d(0, 0, 0, 0deg);
  -ms-transform: rotate3d(0, 0, 0, 0deg);
  transform: rotate3d(0, 0, 0, 0deg);
}

.hex .hexTitle,
.hex .hexDescription {
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
  font-weight: 300;
  -webkit-transition: -webkit-transform .2s ease-out, opacity .3s ease-out;
  transition: transform .2s ease-out, opacity .3s ease-out;
}

.hex .hexTitle {
  bottom: 50%;
  padding-top: 50%;
  z-index: 1;
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.hex .hexTitle::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 45%;
  width: 10%;
  text-align: center;
  border-bottom: 1px solid #fff;
}

.hex .hexDescription {
  top: 50%;
  padding-bottom: 50%;
  -webkit-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}


/*** HOVER EFFECT  **********************************************************************/
.hexLink:hover .hexTitle,
.hexLink:hover .hexDescription,
.hexLink:focus .hexTitle,
.hexLink:focus .hexDescription,
.hexLinkOpen .hexTitle,
.hexLinkOpen .hexDescription {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/

@media (min-width:theme('screens.2xl')) {

  /* <- 5-4  hexagons per row */
  #hexGrid {
    padding-bottom: 5.5%;
  }

  .hex:nth-child(9n+6) {
    /* first hexagon of even rows */
    grid-column-start: 2;
  }
}

@media (min-width: theme('screens.xl')) and (max-width: theme('screens.2xl')) {

  /* <- 4-3  hexagons per row */
  #hexGrid {
    grid-template-columns: repeat(8, 1fr);
    padding-bottom: 7.4%;
  }

  .hex:nth-child(7n+5) {
    /* first hexagon of even rows */
    grid-column-start: 2;
  }
}

@media (min-width: theme('screens.md')) and (max-width: theme('screens.xl')) {

  /* <- 3-2  hexagons per row */
  #hexGrid {
    grid-template-columns: repeat(6, 1fr);
    padding-bottom: 11.2%;
  }

  .hex:nth-child(5n+4) {
    /* first hexagon of even rows */
    grid-column-start: 2;
  }
}

@media (max-width: theme('screens.md')) {

  /* <- 2-1  hexagons per row */
  #hexGrid {
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 15%;
    grid-gap: 5px;
  }

  .hex:nth-child(3n+3) {
    /* first hexagon of even rows */
    grid-column-start: 2;
  }
}